<template>
  <form @submit.prevent="submitDependentes">
    <div class="form__div">
      <input
        :id="'firstName' + props.formNumber"
        class="form__input"
        type="text"
        placeholder=" "
        v-model="firstName"
        required
      />
      <label :for="'firstName' + props.formNumber" class="form__label"
        >First name</label
      >
    </div>
    <div class="form__div">
      <input
        :id="'lastName' + props.formNumber"
        class="form__input"
        type="text"
        placeholder=" "
        v-model="lastName"
        required
      />
      <label :for="'lastName' + props.formNumber" class="form__label"
        >Last name</label
      >
    </div>
    <div class="form__div">
      <input
        :id="'username' + props.formNumber"
        class="form__input"
        type="text"
        placeholder=" "
        v-model="username"
        required
      />
      <label :for="'username' + props.formNumber" class="form__label"
        >Username</label
      >
    </div>
    <div class="form__div">
      <flat-pickr
        v-model="dateBirth"
        :config="config"
        placeholder="Date of birth"
        name="birthdate"
        class="date-input"
        required
      ></flat-pickr>
    </div>
    <div class="form__div">
      <input 
        :id="`phone_number_${props.formNumber}`"  
        class="form__input" 
        type="text" 
        placeholder=" " 
        v-model="phoneNumber" 
      />
      <label :for="`phone_number_${props.formNumber}`"  class="form__label">Phone number (optional)</label>
    </div>
    <div class="flex items-center mb-4">
      <input class="ml-1" type="checkbox" v-model="acceptedOptIn" />
      <span class="ml-2">
        Opt In for Mastermind text messaging for important training updates
      </span>
    </div>
    <div class="mb-4">
      <select
        class="p-4 w-full bg-white text-sm"
        style="border: 3px solid #1d2e48"
        v-model="gender"
        required
      >
        <option disabled value="">Gender</option>
        <option value="Male">Male</option>
        <option value="Female">Female</option>
        <option value="Choose to Not Disclose">Choose to Not Disclose</option>
      </select>
    </div>
    <div class="mb-4">
      <select
        class="p-4 w-full bg-white text-sm"
        style="border: 3px solid #1d2e48"
        v-model="trainingPurpose"
        required
      >
        <option disabled value="">What are you using Mastermind Cognitive Training for?</option>
        <option value="Sports">Sports</option>
        <option value="Academics">Academics</option>
        <option value="Aging Adults">Business</option>
        <option value="Aging Adults">Military/First Responders</option>
        <option value="Aging Adults">Aging Adults</option>
      </select>
    </div>
    <div class="mb-4" v-if="trainingPurpose === 'Sports'">
      <select
        class="p-4 w-full bg-white text-sm"
        style="border: 3px solid #1d2e48"
        v-model="sport"
        required
      >
        <option disabled value="">Primary Sport</option>
        <option value="Baseball">Baseball</option>
        <option value="Basketball">Basketball</option>
        <option value="Boxing/MMA">Boxing/MMA</option>
        <option value="eSports">eSports</option>
        <option value="Football">Football</option>
        <option value="Golf">Golf</option>
        <option value="Gymnastics">Gymnastics</option>
        <option value="Hockey">Hockey</option>
        <option value="Lacrosse">Lacrosse</option>
        <option value="Pickleball">Pickleball</option>
        <option value="Skiing">Skiing</option>
        <option value="Soccer">Soccer</option>
        <option value="Softball">Softball</option>
        <option value="Target Sports">Target Sports</option>
        <option value="Tennis">Tennis</option>
        <option value="Track & Field">Track & Field</option>
        <option value="Volleyball">Volleyball</option>
        <option value="Wrestling">Wrestling</option>
        <option value="Other">Other</option>
      </select>
    </div>
    <button
      id="b-add"
      class="mb-5 bg-mindaro w-full p-3 font-semibold" 
      type="submit"
      :disabled="props.processingRegistration"
    >
      <em v-if="props.processingRegistration" class="fa fa-spinner fa-spin"></em>
      Add Player
    </button>
  </form>
</template>

<script setup>
import { ref, watch } from "vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { formatPhoneNumber } from "@/utils/formatPhoneNumber.js";
import { calculateAge } from "@/utils/calculateAge.js";

const config = {
  altInput: true,
  dateFormat: "m-d-Y",
  altFormat: "m-d-Y",
  allowInput: true,
  disableMobile: "true",
};

const emit = defineEmits(["profileAdded"]);
const props = defineProps({
  formNumber: {
    type: Number,
    default: 0
  },
  processingRegistration: {
    type: Boolean,
    default: false,
  }
});

const firstName = ref(null);
const lastName = ref(null);
const phoneNumber = ref("");
const acceptedOptIn = ref(false);
const username = ref(null);
const dateBirth = ref(null);
const trainingPurpose = ref("")
const sport = ref("");
const gender = ref("");

function submitDependentes() {
  const dateFormatted = formatDate(dateBirth.value);
  const data = {
    firstName: firstName.value,
    lastName: lastName.value,
    username: username.value,
    dateBirth: dateFormatted,
    trainingPurpose: trainingPurpose.value,
    sport: sport.value,
    gender: gender.value,
    phoneNumber: phoneNumber.value,
    acceptedOptIn: acceptedOptIn.value
  };

  return emit("profileAdded", data);
}

function formatDate(date) {
  const dateAdded = new Date(date.replace(/-/g, "/"));
  return `${dateAdded.getFullYear()}-${
    dateAdded.getMonth() + 1
  }-${dateAdded.getDate()}`;
}

watch(dateBirth, (newValue) => {
  if (newValue) {
    const age = calculateAge(newValue);
    if (age > 13) {
      acceptedOptIn.value = true;
    } else {
      acceptedOptIn.value = false;
    }
  }
});

watch(phoneNumber, (newValue, oldValue) => {
  const formatted = formatPhoneNumber(newValue);
  if (newValue !== formatted) {
    phoneNumber.value = formatted;
  }
});

watch(trainingPurpose, (newValue) => {
  if (newValue !== "Sports") {
    sport.value = "";
  }
});
</script>

<style>
#b-add:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

#b-add {
  background-color: #edfe86;
  border: none;
  padding: 11px;
  width: 100%;
  cursor: pointer;
}

.date-input {
  font-size: 16px;
  width: 100%;
  padding: 0.8rem;
  background-color: #ecf0f5;
  font-weight: 500;
  z-index: 2;
}

.date-input::placeholder {
  color: rgb(156, 148, 135);
}

.date-input:focus {
  border: 3px solid #1d2e48;
  border-radius: 0%;
  background-color: #fff;
}

.date-input:not(:placeholder-shown).date-input:not(:focus) {
  border: 3px solid #1d2e48;
  background-color: #fff;
  border-radius: 0%;
}
</style>
