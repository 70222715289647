<template>
  <div id="login-view" class="grow bg-white w-full overflow-auto h-full">
    <div class="m-auto w-11/12 md:w-4/12">
      <div id="logo" class="mt-12">
        <Logo :path="logo" width="120" className="flex justify-center"></Logo>
      </div>
      <div v-if="isFreeTrial">
        <h5 class="text-start mt-4 font-semibold text-xl">
          START YOUR FREE TRIAL
        </h5>
        <div class="flex items-center">
          <p class="text-gray-400 my-3 text-center">
            Add your player profile information
          </p>
          <span
            class="info-icon ml-2"
            title="A player profile is the user that will use the Mastermind experience"
            >&#x1F6C8;</span
          >
        </div>
      </div>
      <div v-else>
        <StepProgressBar
          v-if="fromView !== 'profile'"
          :steps="3"
          :stepSelected="3"
          class="mt-8"
        />
        <h5 class="m-auto text-center mt-14 font-semibold text-xl mb-8">
          ADD PLAYERS
        </h5>
      </div>
      <div v-for="profile in parseInt(nProfiles)" :key="profile">
        <div
          @click="displayForm(profile)"
          class="p-3 mt-4 bg-gray-200 text-color font-semibold flex justify-between items-center"
        >
          <p>Player {{ profile }}</p>
          <em class="fa-solid fa-chevron-down"></em>
        </div>
        <div class="p-5" v-show="showSection === profile">
          <CreatePlayerForm
            :formNumber="profile"
            :processingRegistration="processingRegistration"
            @profileAdded="submitDependentes"
          />
        </div>
      </div>

      <div v-if="showErrorMessage" class="text-center text-danger mt-2">
        <em class="fa-solid fa-circle-exclamation"></em>
        <span class="ms-1">
          {{ errorMessage }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useToast } from "vue-toastification";
import { useRoute, useRouter } from "vue-router";
import Logo from "@/components/auth/Logo";
import CreatePlayerForm from "@/components/Players/CreatePlayerForm.vue";
import StepProgressBar from "@/components/general/StepProgressBar.vue";
import { getGTMEvents, updateGTMEvents } from "@/services/user/user.js";
import { triggerAccountRegisteredEvent } from "@/services/gtmTracker/gtmTracker.js";
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

const route = useRoute();
const router = useRouter();
const toast = useToast();
const store = useStore();

const nProfiles = ref(0);
const profilesCreated = ref(0);
const logo = ref(require("@/assets/icons/mm-icon.svg"));
const parentEmail = ref(null);
const showSection = ref(0);
const showErrorMessage = ref(false);
const errorMessage = ref(null);
const processingRegistration = ref(false);
const fromView = ref("");
const isFreeTrial = ref(false);

const user = computed(() => store.getters["user/getUserData"]);

const createDependent = async (data) =>
  store.dispatch("user/createDependent", data);

const setUserIsLogged = (data) => store.commit("user/setUserIsLogged", data);

onMounted(() => {
  const { n, registrationMethod, from } = route.query;

  fromView.value = from;
  nProfiles.value = parseInt(n);
  isFreeTrial.value = registrationMethod === "free-trial";
  parentEmail.value = sessionStorage.getItem("parentEmail");
  getUserGTMEvents(registrationMethod);
});

async function getUserGTMEvents(registrationMethod) {
  const userID = user.value["id"];
  const gtmEvents = await getGTMEvents(userID);
  if (!gtmEvents.data.gtm_events?.account_registered) {
    await updateGTMEvents(userID, { account_registered: true });
    triggerAccountRegisteredEvent(registrationMethod);
  }
}

function displayForm(id) {
  showSection.value = id !== showSection.value ? id : 0;
}

async function submitDependentes(data) {
  try {
    processingRegistration.value = true;
    await createDependent({
      firstName: data.firstName,
      lastName: data.lastName,
      username: data.username,
      ageRange: "adult",
      brainHemisphere: "left",
      email: parentEmail.value,
      gender: data.gender,
      dateBirth: data.dateBirth,
      trainingPurpose: data.trainingPurpose,
      sport: data.sport,
      phoneNumber: data.phoneNumber ? `+1${data.phoneNumber}` : "",
      acceptedOptIn: data.acceptedOptIn,
      fromAddPlayer: fromView.value === "profile",
    });

    profilesCreated.value++;
    showSection.value++;
    toast.success(
      `Profile registered succesfully ${profilesCreated.value}/${nProfiles.value}`
    );
    processingRegistration.value = false;
    if (fromView.value === "profile") {
      return router.go(-1);
    }
    if (profilesCreated.value === nProfiles.value) {
      setUserIsLogged(true);
      return router.push({
        name: "Home",
        query: { isFreeTrial: isFreeTrial.value },
      });
    }
  } catch (err) {
    processingRegistration.value = false;
    toast.error(err.message);
  }
}
</script>

<style scoped>
ul > li:hover {
  cursor: pointer;
}

@media (min-height: 800px) {
  #logo {
    margin: 4rem auto;
    width: 128px;
  }
}
</style>
